import { useEffect, useRef, useState } from "react";
import TDLogo from "../assets/Logo lockup reverse.png";
import Upload from "../assets/Upload Symbol.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox styles
import { useFormik } from "formik";
import {
  ApplicationFormSchema,
  ContactFormSchema,
  DistributorSchema,
  PartnerSchema,
} from "../utils/form-schemas/partner.schema";
import {
  validateEmail,
  validatePhoneNumberWithGoogle,
} from "../utils/functions";
import axios from "axios";
import { Loader } from "../checkout-screens/loader";
import { useSnackbar } from "notistack";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

// const HubspotContactForm = () => {
//   const dropdownRef = useRef(null);
//   const [showAddressDropdown, setShowAddressDropdown] = useState(false);
//   const [addresses, setAddresses] = useState([]);
//   const [addressSearchText, setAddressSearchText] = useState(""); // State to store the selected address
//   const [selectedAddress, setSelectedAddress] = useState(null);
//   const [stateInputElement, setStateInputElement] = useState(null);

//   useEffect(() => {
//     // console.log("rednere", console.count());
//     const script = document.createElement("script");
//     script.src = "https://js.hsforms.net/forms/v2.js";
//     document.body.appendChild(script);

//     script.addEventListener("load", () => {
//       // @TS-ignore
//       if (window.hbspt) {
//         // @TS-ignore
//         window.hbspt.forms.create({
//           portalId: "139696826",
//           formId: "0b101e17-798a-46d4-ae52-f9ea1bd2174a",
//           target: "#hubspotForm",
//           onFormReady: function ($form) {
//             const inputElement = $form[0].querySelector(
//               "input[name='0-2/address']"
//             ); // Adjust selector as needed
//             // inputElement.setAttribute("_id", "address-input-element");
//             // console.log({ inputElement });
//             setStateInputElement(inputElement);
//             // inputElement._idid = "address-input-element";
//             // if (selectedAddress)
//             //   inputElement.value = selectedAddress?.description;
//             inputElement.addEventListener("input", (event) => {
//               // console.log("ssa", event.target.value);
//               if (!event.target.value.length)
//                 return setShowAddressDropdown(false);
//               if (selectedAddress) setSelectedAddress(null);
//               setAddressSearchText(event.target.value);
//             });
//           },
//         });
//       }
//     });
//   }, [selectedAddress]);

//   // Close dropdown if clicked outside of it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setShowAddressDropdown(false); // Close dropdown when clicking outside
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dropdownRef]);

//   useEffect(() => {
//     if (!addressSearchText || selectedAddress?.place_id)
//       return setShowAddressDropdown(false);
//     const timer = setTimeout(() => handleFetchGoogleLocations(), 500);
//     return () => {
//       clearTimeout(timer); // Clear previous timeout on input change
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [addressSearchText]);

//   const handleFetchGoogleLocations = async () => {
//     try {
//       const {
//         data: { predictions },
//       } = await axios.post(
//         "https://lp75wmodc2.execute-api.us-east-1.amazonaws.com/dev/places-search",
//         { query: addressSearchText },
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_ADDRESS_MAP_KEY}`,
//           },
//         }
//       );
//       setAddresses(predictions);
//       if (!showAddressDropdown) setShowAddressDropdown(true);
//     } catch (error) {
//       console.log({ error });
//     }
//   };

//   // useEffect(() => {}, []);

//   return (
//     <div>
//       <div id='hubspotForm'></div>
//       {showAddressDropdown ? (
//         <div className='places_list' ref={dropdownRef}>
//           {addresses.map((address, _id) => (
//             <p
//               key={_id}
//               onClick={() => {
//                 setAddressSearchText("");
//                 console.log("selected ths", address);
//                 setSelectedAddress(address);
//                 setShowAddressDropdown(false);
//                 stateInputElement.value = address?.description;
//               }}
//             >
//               {address?.description}
//             </p>
//           ))}
//         </div>
//       ) : null}
//     </div>
//   );
// };

export function NewFooter({
  isDistributor = false,
  isPartner = false,
  isApplicationForm = false,
  brands = [],
  careerDetails = null,
  selectedBrand = "",
  items = [],
}) {
  const inputRef = useRef();
  const [addressString, setAddressString] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessFul, setIsSuccessful] = useState(false);
  const [showCustomDropdown, setShowCustomDropdown] = useState(false);
  const isDev = process.env.NODE_ENV !== "production";
  const [uploadedFile, setUploadedFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      country: "",
      countryCode: "",
      formattedAddress: "",
      name: "",
      email: "",
      phoneNumber: "",
      formattedPhoneNumber: "",
      companyName: "",
      supportingText: "",
      enquiry: "",
      brands: "",
      uploadedDoc: "",
      selectedCareer: "",
    },
    onSubmit: (values) => handleSubmitForm(values),
    validationSchema: isPartner
      ? PartnerSchema
      : isApplicationForm
      ? ApplicationFormSchema
      : isDistributor
      ? DistributorSchema
      : ContactFormSchema,
  });

  const mapContainer = useRef(null); // Reference to map DOM element
  const map = useRef(null); // Reference to the map object itself
  const lng = -122.41728;
  const lat = 37.77711;
  const zoom = 15;

  useEffect(() => {
    if (selectedBrand) formik.setFieldValue("brands", selectedBrand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  useEffect(() => {
    if (careerDetails && careerDetails?.jobTitle)
      formik.setFieldValue("selectedCareer", careerDetails.jobTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerDetails]);

  useEffect(() => {
    if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current, // The HTML element to render the map into
      style: "mapbox://styles/mapbox/light-v10", // Mapbox style URL
      center: [lng, lat], // Initial center [lng, lat]
      zoom: zoom, // Initial zoom level
    });

    // Create a custom HTML element for the marker
    const markerDiv = document.createElement("div");
    markerDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="70" height="40" viewBox="0 0 256 256" xml:space="preserve">

<defs>
</defs>
<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<path d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z M 45 48.705 c -8.035 0 -14.548 -6.513 -14.548 -14.548 c 0 -8.035 6.513 -14.548 14.548 -14.548 s 14.548 6.513 14.548 14.548 C 59.548 42.192 53.035 48.705 45 48.705 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(240,147,55); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
</g>
</svg>`;

    new mapboxgl.Marker(markerDiv)
      .setLngLat([lng, lat]) // Set marker coordinates
      .addTo(map.current); // Add marker to the map

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const googleScript = document.createElement("script");
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}&libraries=places`;
    googleScript.async = true;
    googleScript.defer = true;

    googleScript.onload = () => {
      // Initialize Autocomplete after Google Maps script is loaded
      if (inputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const { address_components } = place;
          const { long_name: country, short_name: countryCode } =
            address_components.find((el) => el.types.includes("country"));
          const { long_name: state } = address_components.find((el) =>
            el.types.includes("administrative_area_level_1")
          );
          const cityAddress = address_components.find((el) =>
            el.types.includes("postal_town")
          );
          formik.setFieldValue("state", cityAddress?.long_name || state);
          formik.setFieldValue("country", country || "");
          setAddressString("");
          formik.setFieldValue("countryCode", countryCode || "");
          setAddressString(place.formatted_address || "");
          formik.setFieldValue(
            "formattedAddress",
            place.formatted_address || ""
          );
          const postalCode = address_components.find((el) =>
            el.types.includes("postal_code")
          );
          formik.setFieldValue("postcode", postalCode?.long_name || "");
        });
      }
    };

    document.head.appendChild(googleScript);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(googleScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressString]); // Empty dependency array ensures that this useEffect runs only once on mount

  const handleDocumentUpload = (uploadInputId) => {
    document.getElementById(uploadInputId).click();
  };

  const handleFileChange = (event, documentName) => {
    const uploadedFile = event.target.files[0];
    const allowedTypes = ["application/pdf"];
    if (!allowedTypes.includes(uploadedFile.type)) {
      const message = "Invalid document type uploaded";
      enqueueSnackbar(message, { variant: "error" });
      return formik.setFieldError("uploadedDoc", message);
    }
    const maxSizeInMB = 3; // Maximum allowed size in MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (uploadedFile && uploadedFile.size > maxSizeInBytes) {
      const message = "Max size exceeded for document. Target size is 3MB";
      enqueueSnackbar(message, { variant: "error" });
      return formik.setFieldError("uploadedDoc", message);
    }
    const reader = new FileReader();
    reader.onloadend = function () {
      const image = {
        type: documentName,
        file64: reader.result,
        file: uploadedFile.name,
      };
      setUploadedFile(image);
      formik.setFieldValue("data", reader.result.split(",")[1]);
      formik.setFieldValue(
        "filename",
        `${formik.values.name.split(" ").join("-")}-Resume.pdf`
      );
      formik.setFieldValue("uploadedDoc", reader.result);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);
    if (!validateEmail(values.email)) {
      setLoading(false);
      return enqueueSnackbar("Invalid Email Address", { variant: "error" });
    }
    let body = {};
    if (isApplicationForm) {
      // job application
      body = {
        email: process.env.REACT_APP_CAREER_TD_MAIL,
        subject: `Application for the role of a ${values.selectedCareer}`,
        text: values.supportingText,
        data: values.data,
        filename: values.filename,
      };
      await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/send-mail`,
        body
      );
      formik.resetForm();
      return setIsSuccessful(true);
    }
    if (!isDistributor && !isPartner) {
      body = {
        ...body,
        name: values.name,
        email: process.env.REACT_APP_HELLO_TD_MAIL,
        subject: `New contact: ${values.name}`,
        text: `
Hello, a new contact was created. Their details are listed below: 

Name: ${values.name} 

Email: ${values.email.toLowerCase()}

Enquiry: ${values.supportingText}
        `,
      };
      try {
        await axios.post(
          `${process.env.REACT_APP_API_DRIVER_APP}/send-mail`,
          body
        );
        formik.resetForm();
        return setIsSuccessful(true);
      } catch (error) {
        setLoading(false);
        return enqueueSnackbar(
          "Error submitting form. Please try again later.",
          { variant: "error" }
        );
      }
    } else {
      if (!values?.countryCode) {
        formik.setFieldError("formattedAddress", "Invalid Address");
        setLoading(false);
        return enqueueSnackbar("Invalid Address", { variant: "error" });
      }
      if (values.phoneNumber) {
        const { isNumberValid, formattedNumber } =
          validatePhoneNumberWithGoogle(values.phoneNumber, values.countryCode);
        if (!isNumberValid) {
          setLoading(false);
          return enqueueSnackbar("Invalid Phone Number", { variant: "error" });
        }
        values.formattedPhoneNumber = formattedNumber;
        formik.setFieldValue("formattedPhoneNumber", formattedNumber);
      }
      body = {
        email: values.email.toLowerCase(),
        companyName: values.companyName,
        firstName: values.name.split(" ")[0] || "",
        lastName: values.name.split(" ")[1] || "",
        phone: values.formattedPhoneNumber,
        description: values.supportingText,
        country: values.country,
        interestedBrands: values?.brands || "",
        zip: values?.postcode || "",
        state: values?.state,
        address: values?.formattedAddress || "",
      };
      handleCreatePartner(body);
    }
  };

  const handleCreatePartner = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
        {
          path: `crm/v3/objects/contacts/${values.email}`,
          method: "GET",
          params: {
            idProperty: "email",
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      // TODO: What happens if there's a duplicate contact
      console.log({ res });
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) return handleCreateContact(values);
      setLoading(false);
      return enqueueSnackbar(
        "Something went wrong while creating your contact. Please try again later",
        { variant: "error" }
      );
    }
  };

  const handleCreateContact = async (values) => {
    try {
      const {
        data: { id: contactId },
      } = await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
        {
          path: `crm/v3/objects/contacts`,
          method: "POST",
          params: {
            properties: {
              email: values.email.toLowerCase(),
              firstname: `${values.firstName}${isDev ? "- TEST" : ""}`,
              lastname: `${values.lastName || ""}${isDev ? "- TEST" : ""}`,
              phone: `${values?.phone}`,
              company: values.companyName,
            },
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      await handleCreateCompany(values, contactId);
    } catch (error) {
      setLoading(false);
      return enqueueSnackbar(
        "Something went wrong while creating your contact. Please check that all your details are valid and try again.",
        { variant: "error" }
      );
    }
  };

  const handleCreateCompany = async (values, contactId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/hubspot-proxy`,
        {
          path: `crm/v3/objects/companies`,
          method: "POST",
          params: {
            properties: {
              name: `${values.companyName}${isDev ? "- TEST" : ""}`,
              city: values.state,
              state: values.state,
              country: values.country,
              zip: values.zip,
              description: values.description,
              interested_brands: values.interestedBrands,
              address: values.address,
            },
            associations: [
              {
                to: {
                  id: contactId,
                },
                types: [
                  {
                    associationCategory: "HUBSPOT_DEFINED",
                    associationTypeId: 280,
                  },
                ],
              },
            ],
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      return setIsSuccessful(true);
    } catch (error) {
      setLoading(false);
      return enqueueSnackbar(
        "Something went wrong while creating your company. Please check that all your details are valid and try again.",
        { variant: "error" }
      );
    }
  };

  return (
    <div className="new-footer" onClick={() => setShowCustomDropdown(false)}>
      {/* <HubspotContactForm /> */}
      <div className="contact-us" id="contactUs">
        {isApplicationForm && (
          <>
            <h3>Apply Now</h3>
            {isSuccessFul ? (
              <div className="success-message">
                <h4>Successfully submitted your application!</h4>
                <p>
                  Thank you for your submission. We will get back to you
                  shortly.
                </p>
              </div>
            ) : (
              <>
                <div className="form">
                  <div className="__1">
                    <div className="input-container">
                      <input
                        placeholder="Your Name"
                        onChange={({ target: { value } }) =>
                          formik.setFieldValue("name", value)
                        }
                        value={formik.values.name}
                      />
                      <p>{formik.touched.name ? formik.errors.name : ""}</p>
                    </div>
                    <div className="input-container">
                      <input
                        placeholder="Your Email"
                        onChange={({ target: { value } }) =>
                          formik.setFieldValue("email", value.toLowerCase())
                        }
                        value={formik.values.email}
                      />
                      <p>{formik.touched.email ? formik.errors.email : ""}</p>
                    </div>
                  </div>
                  <div className="__1">
                    <div
                      className="input-container"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        placeholder="Select the Career title you're interested in"
                        className="select-input"
                        onClick={() =>
                          items?.length
                            ? setShowCustomDropdown(!showCustomDropdown)
                            : null
                        }
                        value={formik.values?.selectedCareer || ""}
                      />
                      <CustomDropdown
                        items={items}
                        selectedItem={formik.values?.selectedCareer || ""}
                        showCustomDropdown={showCustomDropdown}
                        setShowCustomDropdown={setShowCustomDropdown}
                        defaultSelect={true}
                        onSelect={(selectedItem) =>
                          formik.setFieldValue("selectedCareer", selectedItem)
                        }
                      />
                      <p>
                        {formik.touched.selectedCareer
                          ? formik.errors.selectedCareer
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="__1">
                    <textarea
                      placeholder="Any supporting text"
                      id="supportingText"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="actions">
                    {uploadedFile ? (
                      <div className="uploaded-details">
                        <p>{uploadedFile?.file}</p>
                        <i
                          className="bi bi-x-circle-fill"
                          onClick={() => setUploadedFile(null)}
                        />
                      </div>
                    ) : (
                      <div className="upload-cv">
                        <input
                          type="file"
                          id="upload-cv"
                          accept=".pdf"
                          onChange={(e) => handleFileChange(e, "CV")}
                        />
                        <div
                          className="upload-cv-view"
                          onClick={() => handleDocumentUpload("upload-cv")}
                        >
                          <p>Attach your CV and cover letter</p>
                          <img
                            src={Upload}
                            alt="upload-logo"
                            className="img-fluid"
                          />
                        </div>
                        <p className="limit">
                          PDF or Word Doc, maximum size 3mb
                        </p>
                      </div>
                    )}
                    <button
                      className={`td-btn ${loading ? "loading" : ""}`}
                      disabled={loading}
                      onClick={formik.handleSubmit}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <span>Submit</span>
                          <div className="chevron chevron-right" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isApplicationForm ? null : (
          <>
            {isDistributor ? (
              <h3>Become a distributor</h3>
            ) : isPartner ? (
              <h3>Partner With Us Today</h3>
            ) : (
              <h3>Contact Us</h3>
            )}
            <h6>
              {isDistributor || isPartner
                ? "Contact us through the form below and one of our team will be in touch shortly"
                : "Use the form below to get in touch with any questions you may have or to find out more about how TradeDepot can help your business grow."}
            </h6>
            {isSuccessFul ? (
              <div className="success-message">
                <p>
                  Thank you for reaching out. We will get back to you shortly.
                </p>
              </div>
            ) : (
              <div className="form">
                <div className="__1">
                  <div className="input-container">
                    <input
                      placeholder="Your Name"
                      onChange={({ target: { value } }) =>
                        formik.setFieldValue("name", value)
                      }
                      value={formik.values.name}
                    />
                    <p>{formik?.touched?.name ? formik.errors.name : null}</p>
                  </div>
                  <div className="input-container">
                    <input
                      placeholder="Your Email"
                      onChange={({ target: { value } }) =>
                        formik.setFieldValue("email", value.toLowerCase())
                      }
                      value={formik.values.email}
                    />
                    <p>{formik?.touched?.email ? formik.errors.email : null}</p>
                  </div>
                </div>
                {isDistributor || isPartner ? (
                  <>
                    <div className="__1">
                      <div className="input-container">
                        <input
                          placeholder="Company Name"
                          onChange={({ target: { value } }) =>
                            formik.setFieldValue("companyName", value)
                          }
                          value={formik.values.companyName}
                        />
                        <p>
                          {formik?.touched?.companyName
                            ? formik.errors.companyName
                            : null}
                        </p>
                      </div>
                      <div className="input-container">
                        <input
                          placeholder="Phone Number"
                          onChange={({ target: { value } }) =>
                            formik.setFieldValue("phoneNumber", value)
                          }
                          value={formik.values.phoneNumber}
                        />
                        <p>
                          {formik?.touched?.phoneNumber
                            ? formik.errors.phoneNumber
                            : null}
                        </p>
                      </div>
                    </div>
                    <div className="__1">
                      <div className="input-container">
                        <input
                          placeholder="Address"
                          ref={inputRef}
                          onChange={(e) => {
                            setAddressString(e.target.value);
                            formik.setFieldValue(
                              "formattedAddress",
                              e.target.value
                            );
                          }}
                          value={
                            formik.values.formattedAddress || addressString
                          }
                        />

                        <p>
                          {formik?.touched?.formattedAddress
                            ? formik.errors.formattedAddress
                            : null}
                        </p>
                      </div>
                      {!isPartner ? (
                        <div
                          className="input-container"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <input
                            placeholder="I'm interested in..."
                            onClick={() =>
                              setShowCustomDropdown(!showCustomDropdown)
                            }
                            className="select-input"
                            value={formik.values.brands}
                          />
                          <CustomDropdown
                            brands={brands}
                            showCustomDropdown={showCustomDropdown}
                            selectedBrands={formik.values.brands.split(", ")}
                            onSelect={(value) => {
                              const selectedBrands = value.filter(
                                (value) => value.length
                              );
                              formik.setFieldValue(
                                "brands",
                                String(selectedBrands.join(", "))
                              );
                              setShowCustomDropdown(false);
                            }}
                            setShowCustomDropdown={setShowCustomDropdown}
                          />
                          <p>
                            {formik?.touched?.brands
                              ? formik.errors.brands
                              : null}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
                {isDistributor || isPartner ? (
                  <textarea
                    placeholder="Any supporting text"
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue("supportingText", value)
                    }
                    value={formik.values.supportingText}
                  />
                ) : (
                  <textarea
                    placeholder="Your enquiry"
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue("supportingText", value)
                    }
                    value={formik.values.supportingText}
                  />
                )}
                <button
                  className={`td-btn ${loading ? "loading" : ""}`}
                  disabled={loading}
                  onClick={formik.handleSubmit}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <span>Submit</span>
                      <div className="chevron chevron-right" />
                    </>
                  )}
                </button>
              </div>
            )}
          </>
        )}

        <div className="row maps">
          <div className="col-md-7 col-sm-12">
            <div className="map-wrapper">
              <div
                id="map"
                ref={mapContainer}
                style={{ width: "100%", height: "450px" }}
              />
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div className="company-details">
              <h2>TradeDepot Inc</h2>
              <h4>
                1390 Market St, Suite 200, <br />
                CA 94102 San Francisco.
              </h4>
              <h4 id="phone">Tel: +1 (628) 250 2600</h4>
              <div className="socials">
                <a
                  href="http://instagram.com/tradedepot.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="http://facebook.com/tradedepot.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href="http://x.com/tradedepotHQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/tradedepot/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div className="top-row">
          <p>
            <img src={TDLogo} alt="td-logo" />
          </p>
          <ul>
            <li>
              <a href="/brands">Featured Brands</a>
            </li>
            <li>
              <a href="/partners">Partner with Us</a>
            </li>
            <li>
              <a href="/distributors">Become a Distributor</a>
            </li>
            <li>
              <a href="/marketplace">Marketplace</a>
            </li>
            {/* <li>
              <a href='/'>eProcurement Login</a>
            </li> */}
            <li>
              <a href="/blog">Newsroom</a>
            </li>
            <li>
              <a href="/careers">Careers</a>
            </li>
            <li>
              <a href="/resources">Resources</a>
            </li>
          </ul>
        </div>
        <div className="bottom-row">
          <ul>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms">Terms of Service</a>
            </li>
            <li> &copy;TradeDepot. All rights reserved.</li>
          </ul>
          <div className="socials">
            <a
              href="http://instagram.com/tradedepot.co"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-instagram"></i>
            </a>
            <a
              href="http://facebook.com/tradedepot.co"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              href="http://x.com/tradedepotHQ"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-twitter-x"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/tradedepot/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomDropdown = ({
  brands = [],
  items = [],
  onSelect,
  selectedBrands: customSelectedBrands = [],
  setShowCustomDropdown,
  showCustomDropdown,
  defaultSelect = false,
  selectedItem,
}) => {
  const [selectedBrands, setSelectedBrands] = useState([
    ...customSelectedBrands,
  ]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!customSelectedBrands.length) return;
    const csBrands = customSelectedBrands.map((el) =>
      brands.find((dl) => dl === el)
    );
    setSelectedBrands([...csBrands]);
  }, [customSelectedBrands, brands]);

  useEffect(() => {
    if (showCustomDropdown) return setSelectedBrands([...customSelectedBrands]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomDropdown]);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCustomDropdown(false); // Close dropdown when clicking outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return defaultSelect ? (
    showCustomDropdown ? (
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="list">
          {items.map((item, key) => (
            <p key={key}>
              <input
                type="checkbox"
                value={item}
                id={item}
                checked={selectedItem === item}
                onClick={() => onSelect(item)}
              />
              <span onClick={() => onSelect(item)}>{item}</span>
            </p>
          ))}
        </div>
        <div className="actions-button">
          <div>
            <button onClick={() => setShowCustomDropdown(false)}>Save</button>
          </div>
        </div>
      </div>
    ) : null
  ) : (
    <>
      {showCustomDropdown ? (
        <div className="custom-dropdown" ref={dropdownRef}>
          <div className="list">
            {brands.map((brand, key) => (
              <p key={key}>
                <input
                  type="checkbox"
                  value={brand}
                  id={brand}
                  checked={selectedBrands.includes(brand)}
                  onClick={() => {
                    if (selectedBrands.includes(brand)) {
                      setSelectedBrands([
                        ...selectedBrands.filter((b) => b !== brand),
                      ]);
                    } else setSelectedBrands([...selectedBrands, brand]);
                  }}
                />
                <span>{brand}</span>
              </p>
            ))}
          </div>
          <div className="actions-button">
            <div>
              <button onClick={() => setSelectedBrands([])}>Clear</button>
              <button onClick={() => onSelect(selectedBrands)}>Save</button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
